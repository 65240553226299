<template>
    <div>

        <button-back/>

        <div v-if="type == 'user'" class="protocol-card">

            <div class="protocol-card-title">
                <b>《用户协议》</b>
            </div>
            <div class="protocol-card-content">
                1.用户在使用时应当严格遵守《中华人民共和国计算机信息网络国际联网管理暂行规定》、《互联网信息服务管理办法》等国家法律法规。
                <br/>
                <br/>
                2.不得进行诸如黑客攻击、散布计算机病毒等危害行为。
                <br/>
                <br/>
                3.用户不得复制、转售或转授权任何部分或者全部的本站的服务。
                <br/>
                <br/>
                4.本站特有的标识、版面设计等版权均属上海交大后勤发展有限公司所有，未经本公司许可，不得任意复制或转载。
                <br/>
                <br/>
                5.用户应当保护好自己的用户名及密码不被泄露。因泄露用户名或密码导致的后果，责任由用户自己承担。因黑客行为及用户保管疏忽导致账户非法使用，本站不承担任何责任。
                <br/>
                <br/>
                6．在使用我们服务的过程中，如遇到问题，您可以通过拨打xxx-xxxxxxxx或将遇到的具体问题发送电子邮件至：xxx@xxx.com联系我们。
                <br/>
                <br/>
                7．本协议解释权及修订权归xxx所有。
            </div>
        </div>
        <div v-else-if="type == 'register'" class="protocol-card">
            <div class="protocol-card-title">
                <b>《注册协议》</b>
            </div>
            <div class="protocol-card-content">
                1.您在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为正式用户。
                <br/>
                <br/>
                2.注册时用户应当提供真实的手机号通过短信校验后完成注册。
                <br/>
                <br/>
                3.用户应当保护好自己的用户名及密码不被泄露。因泄露用户名或密码导致的后果，责任由用户自己承担。因黑客行为及用户保管疏忽导致账户非法使用，本站不承担任何责任。
                <br/>
                <br/>
                4.本协议解释权及修订权归上海交大后勤发展有限公司所有。
            </div>
        </div>
        <div v-else-if="type == 'diy'">
            <div class="protocol-card-title">
                <b>{{title}}</b>
            </div>
            <div class="protocol-card-content">
				<div class="text-wrapper">{{content}}</div>
            </div>
        </div>
    </div>
</template>

<script>

    import ButtonBack from "@/components/ButtonBack";

    export default {
        name: "ProtocolPage",
        components: {
            ButtonBack
        },
        props: ['type', 'title', 'content'],
        data() {
            return {
                // type: this.$route.params['type']
            }
        },
    }
</script>

<style scoped lang="less">

    .protocol-card {
        background: #FFFFFF;
        box-shadow: 0px -4px 100px #E5E5E5;
        border-radius: 50px 50px 0px 0px;
        position: absolute;
        width: 100%;
        height: calc(100vh - 68px);
        top: 68px;
        text-align: left;

        &-title {
            text-align: center;
            margin-top: 10px;
            font-size: 18px;
        }

        &-content {
            padding: 20px;
            font-size: 14px;
        }
    }
	.text-wrapper {
		white-space: pre-wrap;
	}
</style>
